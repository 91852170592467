import React from "react"
import { graphql } from "gatsby"
import Layout from "@components/layout"
import Html from "@components/html"
import ResourceHeader from "@components/resources/header"
import Container from "@components/layout/container"
import PropTypes from "prop-types"
import Flex from "@components/elements/flex"
import { ResourceGrid } from "@components/resources/resource-grid"
import theme from "@styles/theme"
import { ResourceWrapper } from "@components/resources/resource-wrapper"
import { RelatedResourceGrid } from "@components/dashboard-examples/related-resource-grid"
import { cleanDashboardName } from "@utils/name-cleaner"
import { NeutralCTA } from "@components/cta/eop/neutral"

const DashboardExampleCategory = ({ data, pageContext }) => {
  const category = data.category
  const image = category.image
  const imageUrl = image?.cdn
  const ctaName = cleanDashboardName(category.name)
  const relatedExamples =
    category.related_examples?.length > 0 ? category.related_examples : null

  return (
    <Layout
      image={imageUrl}
      fullWidth={true}
      canonical={pageContext.slug}
      title={category.title}
      description={category.summary}
      seo={category.seo}
      body={category.body}
      date_updated={category.date_updated}
    >
      <article>
        <ResourceWrapper
          gap="6rem"
          margin="0 0 6rem"
          marginMd="0 0 4rem"
          marginSm="0 0 2rem"
        >
          <ResourceHeader
            name={category.name}
            alt={`${category.name} Dashboard Examples`}
            subtitle={category.subtitle}
            image={image}
            newCta
            ctaCategory={ctaName}
            links={[
              { text: "Resources", url: "/resources" },
              {
                text: "Dashboard Examples",
                url: "/resources/dashboard-examples",
              },
              {
                text: category.name,
              },
            ]}
          />
          <Container text>
            <Flex fontSize={theme.defaults.fontSize.p} gap="2rem">
              <Html
                parseElements
                inherit
                html={category.body}
                images={category.body_images}
                cta
              />
            </Flex>
          </Container>
          {relatedExamples && (
            <Container>
              <RelatedResourceGrid
                title={category.related_title}
                examples={relatedExamples}
              />
            </Container>
          )}
          {category.body2 && (
            <Container text>
              <Flex fontSize={theme.defaults.fontSize.p} gap="2rem">
                <Html
                  inherit
                  parseElements
                  html={category.body2}
                  images={category.body2_images}
                  cta
                />
              </Flex>
            </Container>
          )}
          {category.body3 && (
            <Container text>
              <Flex fontSize={theme.defaults.fontSize.p} gap="2rem">
                <Html
                  inherit
                  parseElements
                  html={category.body3}
                  images={category.body3_images}
                  cta
                />
              </Flex>
            </Container>
          )}
          {category.show_related && !relatedExamples && (
            <Container>
              <ResourceGrid
                title={ctaName}
                examples={category.dashboard_examples}
              />
            </Container>
          )}
        </ResourceWrapper>
        <NeutralCTA />
      </article>
    </Layout>
  )
}

DashboardExampleCategory.propTypes = {
  data: PropTypes.object.isRequired,
  pageContext: PropTypes.object.isRequired,
}

export default DashboardExampleCategory

export const pageQuery = graphql`
  query DashboardExampleCategoryPageQuery($id: String!) {
    category: dashboardExamplesCategories(id: { eq: $id }) {
      related_title
      related_examples {
        slug
        name
        id
        summary
        related_description
        image {
          cdn
          placeholder
          id
          title
        }
        status
        category {
          name
          slug
        }
      }
      dashboard_examples {
        id
        title
        name
        subtitle
        slug
        category {
          slug
          name
        }
        image {
          title
          id
          cdn
          placeholder
          directus_id
        }
      }
      image {
        directus_id
        id
        title
        cdn
        placeholder
      }
      body
      body2
      body3
      body_images {
        cdn
        placeholder
        directus_id
        id
        title
      }
      body2_images {
        cdn
        placeholder
        id
        title
        directus_id
      }
      body3_images {
        cdn
        placeholder
        id
        title
        directus_id
      }
      name
      id
      title
      summary
      date_updated
      subtitle
      slug
      status
      seo {
        ...seoData
      }
      show_related
    }
  }
`
